import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";

function Header() {
    const [isExpanded, toggleExpansion] = useState(false);
    const { site } = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <header className="bg-purple-800">
            <div className="flex flex-wrap items-center justify-between max-w-4xl p-4 mx-auto md:p-8">
                <Link to="/">
                    <h1 className="flex items-center text-white no-underline">
                        <img
                            src="https://raw.githubusercontent.com/clementsjj/servers/master/bezukhov/src/jjlogo.png"
                            className="w-16"
                        />
                        <span
                            className={
                                typeof window !== "undefined" &&
                                window.location.pathname == `/`
                                    ? "ml-3 text-xl font-bold tracking-tight underline"
                                    : "ml-3 text-xl font-bold tracking-tight"
                            }>
                            {site.siteMetadata.title}
                        </span>
                    </h1>
                </Link>

                <button
                    className="flex items-center block px-3 py-2 text-white border border-white rounded md:hidden"
                    onClick={() => toggleExpansion(!isExpanded)}>
                    <svg
                        className="w-3 h-3 fill-current"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <title>Menu</title>
                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                    </svg>
                </button>

                <nav
                    className={`${
                        isExpanded ? `block` : `hidden`
                    } md:block md:flex md:items-center w-full md:w-auto`}>
                    <Link
                        className={
                            typeof window !== "undefined" &&
                            window.location.pathname == `/`
                                ? "block mt-4 text-white md:inline-block md:mt-0 md:ml-6 underline font-semibold"
                                : "block mt-4 text-white no-underline md:inline-block md:mt-0 md:ml-6"
                        }
                        to="/">
                        Home
                    </Link>
                    {[
                        {
                            route: `/about`,
                            title: `About`,
                        },
                        {
                            route: `/contact`,
                            title: `Contact`,
                        },
                        {
                            route: `/gifs`,
                            title: `Gifs`,
                        },
                        {
                            route: `/projects`,
                            title: `Projects`,
                        },
                    ].map((link) => (
                        <Link
                            className={
                                typeof window !== "undefined" &&
                                window.location.pathname ==
                                    `/${link.title.toLowerCase()}`
                                    ? "block mt-4 text-white md:inline-block md:mt-0 md:ml-6 underline font-semibold"
                                    : "block mt-4 text-white no-underline md:inline-block md:mt-0 md:ml-6"
                            }
                            key={link.title}
                            to={link.route}>
                            {link.title}
                        </Link>
                    ))}
                </nav>
            </div>
        </header>
    );
}

export default Header;
