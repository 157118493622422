import PropTypes from "prop-types";
import React from "react";

import Header from "./header";

function Layout({ children }) {
    return (
        <div className="flex flex-col min-h-screen font-sans text-gray-900 bg-gray-700">
            <Header />

            <main className="flex-1 w-full max-w-4xl px-4 py-4 mx-auto md:px-8 md:py-4 bg-gray-700">
                {children}
            </main>

            <footer className="bg-blue-900">
                <nav className="flex justify-between max-w-4xl p-4 mx-auto text-sm md:p-8">
                    <p className="text-white">
                        Created by{` `}
                        <a
                            className="font-bold no-underline"
                            href="https://tsarchild.com"
                            target="_blank"
                            rel="noopener noreferrer">
                            JJ Clements
                        </a>
                    </p>
                    <p>
                        <a
                            href="mailto:clementsjj@gmail.com"
                            className="font-bold text-white no-underline"
                            target="_blank"
                            rel="noopener noreferrer">
                            Email
                        </a>
                    </p>
                    <p>
                        <a
                            className="font-bold text-white no-underline"
                            href="https://github.com/clementsjj"
                            target="_blank"
                            rel="noopener noreferrer">
                            GitHub
                        </a>
                    </p>
                </nav>
            </footer>
        </div>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
